<template>
  <b-row>
    <b-col cols="12">
      <!-- <b-card>
        <b-card-body class="p-0"> -->

          <b-overlay
      :show="loadingWizard"
      rounded="sm"
    >
          <b-alert v-if="errorMsg" variant="danger" show>
            <div class="alert-body">
              <span>{{ errorMsg }}</span>
            </div>
          </b-alert>
         
      <form-wizard
        :title="null"
        :subtitle="null"
        ref="formWiz"
        @on-complete="onComplete"
        @on-loading="setLoading"
        @on-validate="handleValidation"
        @on-error="handleErrorMessage"
        @onNextStep="nextStep"
        @onPreviousStep="previousStep"
        
      >
      <div slot="footer" scope="props">
    <b-button @click="previousStep"  class="btn-xs" variant="primary" v-if="currentTab !=-1" :disabled="currentTab == 0 ? true : false">Prev</b-button>

    <b-button @click="nextStep"  class="btn-xs" variant="primary" style="float: right;" :disabled="nextEnable">{{ nextTitle }}</b-button>
  </div>
  

        <tab-content
          title="Details"
          :selected="true"
          :before-change="() => validateAsync('step0')"
        >
         

          <b-form-group label="Date" label-for="Date">
            <validation-provider
              #default="{ errors }"
              name="date"
              rules="required"
            >
              <b-form-datepicker
                id="datepicker-dateformat1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en"
                v-model="date"
                placeholder="Select Date"
              ></b-form-datepicker>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Company Name" label-for="Company Name">
            <validation-provider
              #default="{ errors }"
              name="carrier"
              rules="required"
            >
              <b-form-input v-model="carrier" readonly></b-form-input>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Vehicle" label-for="Vehicle">
            <v-select
              v-ripple
              v-model="vehicle"
              label="label"
              :options="option"
              :searchable="false"
              placeholder="Select Vehicle"
              v-on:input="vehicleSelection"
            />
          </b-form-group>
          <b-form-group label="Odometer" label-for="Odometer">
            <b-form-input type="number" v-model="odometer"></b-form-input>
          </b-form-group>

          <b-form-group label="Trip Type" style="text-align: -webkit-left">
            <b-form-radio
              v-model="type"
              class="custom-control-primary"
              name="radio-inline"
              value="1"
              v-on:change="TypeChange"
              >Pre-Trip</b-form-radio
            >
            <b-form-radio
              v-model="type"
              class="custom-control-primary"
              name="radio-inline"
              value="2"
              v-on:change="TypeChange"
              >Post-Trip</b-form-radio
            >
          </b-form-group>

            <!-- next-button-text="Go back!"
        @next-button="nextStep" -->
     
        </tab-content>
        <!-- 
      <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        v-model="selected"
        :options="options"
        :aria-describedby="ariaDescribedby"
        plain
        @change.native="ChangeVehicleDefects($event)"
      ></b-form-checkbox-group>
    </b-form-group> -->

        <tab-content
          v-for="(Citem, Cindex) in category_data"
          :key="Cindex + 1"
          :title="Citem.title"
          :before-change="() => validateAsync('step' + Cindex)"
        >
          <h3>{{ Citem.title }}</h3>
          <br />
          <div
            v-for="(item, index) in CategoryWiseData(Citem.id)"
            :key="index"
            style="margin: 8px 5px"
          >
            <b-form-group
              :label="item.title"
              :label-for="item.title"
              v-if="showCheck == false"
            >
            </b-form-group>
            <b-form-checkbox
              v-model="vehicle_defects_data"
              :value="item.id"
              :id="'vehicle_' + item.id"
              @change.native="ChangeVehicleDefects($event, item.id, Citem.id)"
              v-if="showCheck == true"
            >
              {{ item.title }}
            </b-form-checkbox>
          </div>
          <br /><br />
          <div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="good-button"
              @click="nextStep(Cindex+1)"
              :disabled="disableNext"
            >
              <feather-icon icon="ThumbsUpIcon"></feather-icon>
              All Good
            </b-button>
            <br />
            <br />
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="bad-button"
              @click="showStep(Cindex+1)"
              :disabled="disablePrev"
            >
              <feather-icon icon="ThumbsDownIcon"></feather-icon>
              Bad
            </b-button>
          </div>
        </tab-content>

        <tab-content
          title="Remarks"
          :before-change="() => validateAsync('step90')"
          ref="final"
        >
          <b-form-group
            label="Remarks"
            label-for="Remarks"
            style="font-size: 16px; font-weight: bold"
          >
            <b-form-textarea v-model="remarks"></b-form-textarea>
          </b-form-group>

          <b-form-group
            label="Signature"
          >
          <img :src="driverSign" width="75px">

          </b-form-group>
        </tab-content>
      </form-wizard>
      </b-overlay>

      <b-modal
        size="lg"
        id="modal-tall"
        ref="modal-tall"
        title="Add a Video or Picture"
        ok-only
        ok-title="Cancel"
        @ok="handleClose"
        no-close-on-backdrop
        v-bind:hide-footer="true"
      >
        <b-row>
          <validation-observer ref="simpleRules">
            <b-form
              @submit.prevent="onSave"
              @reset.prevent="resetForm"
              class="p-1"
            >
              <b-row>
                <b-col cols="10">
                  <b-form-group label="Upload File" label-cols-md="4">
                    <b-form-file
                      v-model="document"
                      @change.native="onFileChange($event)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="10">
                  <b-form-group
                    label="Message"
                    label-for="a-Message"
                    label-cols-md="4"
                  >
                      <b-form-textarea
                        v-model="message"
                        placeholder="Please enter a message"
                      />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="10">
                  <b-form-group label="" label-for="" label-cols-md="4">
                    <b-button
                      type="submit"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                    >
                      Submit
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-row>
      </b-modal>
      <b-modal
        size="lg"
        id="modal-inspection-check"
        centered
        title="Prior Defects"
        ok-only
        ok-title="Cancel"
        ref="modal-inspection-check"
        no-close-on-backdrop
        v-bind:hide-footer="true"
      >
        <b-row v-for="(Citem, Cindex) in selected_category_data" :key="Cindex">
          <b-col md="3">
            <h5 class="defect-show-title">{{ Citem.title }}</h5>
          </b-col>
          



          <div class="defect-div" v-for="(item, index) in CategoryWiseData(Citem.id)"
            :key="index">

            <b-col
            md="12"
            v-if="selected.length > 0 && selected.includes(item.id) > 0"
          >
            <b-form-group  >
           
              <b-form-checkbox
                v-model="selected"
                :value="item.id"
                :name="item.title"
                :disabled="true"
                >{{ item.title }}
                <div
                  v-if="selected.length > 0 && selected.includes(item.id) > 0"
                  style="display: flex"
                >
                  <b-link v-if="validateIcon(item.id, 1)">
                    <feather-icon
                      icon="FileTextIcon"
                      size="21"
                      @click="ViewDefectDetails(item.id, 1)"
                    />
                  </b-link>

                  <b-link v-if="validateIcon(item.id, 2)">
                    <feather-icon
                      icon="VideoIcon"
                      size="21"
                      @click="ViewDefectDetails(item.id, 2)"
                    />
                  </b-link>

                  <b-link v-if="validateIcon(item.id, 3)">
                    <feather-icon
                      icon="MailIcon"
                      size="21"
                      @click="ViewDefectDetails(item.id, 3)"
                    />
                  </b-link>
                </div>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          </div>
          
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Status" style="text-align: -webkit-left">
              <b-form-radio
                v-model="check_status"
                class="custom-control-primary"
                name="radio-inline"
                value="1"
                >All Resolved</b-form-radio
              >
              <b-form-radio
                v-model="check_status"
                class="custom-control-primary"
                name="radio-inline"
                value="2"
                >Not Resolved</b-form-radio
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label class="d-inline d-lg-none">Description</label>
            <b-form-textarea v-model="verify_message"></b-form-textarea>
          </b-col>
          <b-col md="8">
            <b-form-group label="" label-for="h-status-name" label-cols-md="4">
              <b-button
                class="btn-sm"
                variant="primary"
                @click="onSubmitComment"
              >
                Submit
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        size="lg"
        id="modal-message"
        centered
        title="Message"
        ok-only
        ok-title="Cancel"
        ref="modal-message"
      >
        {{ MessageDetails }}
      </b-modal>
      <!-- </b-card-body>
  </b-card> -->
    </b-col>
  </b-row>
</template>
      
      <script>
import {
  BButton,
  BMedia,
  BAvatar,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRadio,
  BFormTextarea,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
  BCardBody,
  BLink,
  BOverlay
} from "bootstrap-vue";
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import vSelect from "vue-select";
import axios from "axios";
import vueSignature from "vue-signature";

export default {
  components: {
    BAlert,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BFormRadio,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr,
    vSelect,
    FormWizard,
    TabContent,
    BFormFile,
    vueSignature,
    BLink,
    BOverlay
  },
  data() {
    return {
      Sioption: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      check_status: "",
      fileUrl: serverUri + `/upload/`,
      type: "",
      nextTitle : "Next",
      defectCategory:[],
      disablePrev: false,
      disableNext: false,
      MessageDetails: "",
      verify_message: "",
      defectData: [],
      uploadDefectDataStatus: [],
      currentTab:-1,
      selectedTab:0,
      nextEnable:false,
      // selected: "",
      selectedCatId: "",
      document: "",
      message: "",
      showCheck: false,
      loadingWizard: false,
      errorMsg: null,
      count: 0,
      date: "",
      option: [],
      vehicleOptions: [],
      randomKey: 1,
      vehicle_defects: [],
      vehicle_defects_data: [],
      category_data: [],
      selected_category_data: [],
      assignee: [],
      selected: [],
      selectedData: [],
      selectedDefects: [],
      selectedModalDvir: [],
      Output: [],
      address: "",
      vehicle: "",
      companyId: getUserData().company_id,
      carrier: getUserData().company_name,
      userId: getUserData().id,
      userData: getUserData(),
      clockId: router.currentRoute.params.id,
      submitInspection: false,
      odometer: "",
      trailer_no: "",
      remarks: "",
      driverSign: "",
    };
  },
  mounted() {
    this.getVehicleDefects();
    this.getCategories();
    this.getFleetUsers();
    this.getDriverSign();
  },
  computed: {},
  props: {},
  methods: {
    save() {
      var _this = this;
      var png = _this.$refs["signature1"].save();
      var jpeg = _this.$refs["signature1"].save("image/jpeg");
      var svg = _this.$refs["signature1"].save("image/svg+xml");
    },
    clear() {
      var _this = this;
      _this.$refs["signature1"].clear();
    },
    undo() {
      var _this = this;
      _this.$refs["signature1"].undo();
    },

    changedLabel: function (label) {
      console.log("==" + this.vehicle.title);
      //receive the label of the value selected (the label shown in the select, or an empty string)
    },

    vehicleSelection(e) {
      // this.selectedModalDvir = id;
      this.loadingWizard = true;
      this.selected = [];
      this.selectedData = [];
      const vehicle = this.vehicle?.title == undefined ?  '' : this.vehicle.title;
      
      if(vehicle == '')
      {
              this.loadingWizard = false;
              this.errorMsg = '';
              this.nextEnable = false;
              this.selected_category_data = [];
              this.selectedDefects = [];

      } else {

          axios
            .get(
              `${serverUri}/inspections_defects_list_by_vehicle/${vehicle}`
            )
            .then((res) => {
              if (res.data) {
                this.selectedDefects = res.data;

                this.selectedDefects.map((item, index) => {
                  this.defectCategory.push(item.defect_category);
                  this.selectedModalDvir.push(item.dvir_id);
                  this.selected.push(item.vehicle_defect_id);
                  this.selectedData.push({
                    file: item.file,
                    message: item.message,
                    id: item.vehicle_defect_id,
                  });
                });

                this.category_data.map((item, index) => {
                if(this.defectCategory.includes(item.id))
                {
                  this.selected_category_data.push(item);
                }
              });

              this.loadingWizard = false;
              this.errorMsg = '';
              }

              if (this.selectedDefects.length > 0) {
                this.$refs["modal-inspection-check"].show();
              }
              this.nextEnable = false;

            });
        }
    },
    validateIcon(id, type) {
      const Output = [];
      this.selectedData.map((item, index) => {
        if (item.id == id && type == 1) {
          if (item.file != "") {
            const imageExts = ["jpg", "jpeg", "png", "gif", "pdf"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              Output.push(true);
            }
          }
        } else if (item.id == id && type == 2) {
          const imageExts = ["mov", "mp4", "wmv", "avi", "mkv", "webm"];
          const ext = item.file.split(".").pop();
          console.log(ext);
          if (imageExts.includes(ext)) {
            Output.push(true);
          }
        } else if (item.id == id && type == 3) {
          if (item.message != "") {
            Output.push(true);
          }
        }
      });

      return Output.length > 0 ? true : false;
    },

    ViewDefectDetails(defectId, type) {
      const resultI = [];
      const resultV = [];
      const resultM = [];
      console.log(this.selectedDefects);
      this.selectedDefects.map((item, index) => {
        if (
          this.selectedModalDvir[0] == item.dvir_id &&
          item.vehicle_defect_id == defectId
        ) {
          if (type == 1) {
            const imageExts = ["jpg", "jpeg", "png", "gif", "pdf"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              resultI.push(this.fileUrl + item.file);
            }
          } else if (type == 2) {
            const imageExts = ["mov", "mp4", "wmv", "avi", "mkv", "webm"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              resultV.push(this.fileUrl + item.file);
            }
          } else {
            if (item.message != "") {
              resultM.push(item.message);
            }
          }
        }
      });

      if (type == 1) {
        if (resultI.length == 0) {
          alert("No file found.");
          return false;
        }
        window.open(resultI[0], "_blank");
      } else if (type == 2) {
        if (resultV.length == 0) {
          alert("No video file found.");
          return false;
        }

        window.open(resultV[0], "_blank");
      } else {
        if (resultM.length == 0) {
          alert("No message found.");
          return false;
        } else {
          this.MessageDetails = resultM[0];
          this.$refs["modal-message"].show();
        }
      }
    },

    onSubmitComment() {
      if (this.check_status == "") {
        this.showToast("danger", "Warning", "Please choose status.");
        return false;
      }

      if (this.check_status != "") {
        this.submitInspection = true;
        this.showToast("success", "Success", "Your details has been saved");
        this.$refs["modal-inspection-check"].hide();
      }
    },
    handleClose() {
      this.$refs["modal-tall"].hide();
    },
    TypeChange() {
      this.errorMsg = '';
      this.nextEnable = false;

    },
    showStep: function (value) {
      this.showCheck = true;
      this.selectedTab = value;
      this.nextEnable = false;

    },
    nextStep: function (value) {
      this.showCheck = false;
      this.selectedTab = value;
      this.nextEnable = true;
      this.$refs.formWiz.nextTab();
    },

    previousStep: function (value) {
      this.showCheck = false;
      this.nextEnable = true;
      this.nextTitle  = 'Next';
      this.$refs.formWiz.prevTab();
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0];
      this.selectedFile = selectedFile;
      this.progress = 0;
    },
    myFunction: function () {
      this.randomKey = Math.random() * 100; //multiply to generate random number between 0, 100
    },
    onComplete: function () {

      if(this.driverSign == "")
      {
        this.showToast("danger", "Warning", "Please add your signature.");
        return false;
      }

      if (this.submitInspection == false) {
        this.$http
          .post(`${serverUri}/inspections/vehicle`, {
            random_key: this.randomKey,
            date: this.date,
            driver_id: this.userId,
            company_id: this.companyId,
            carrier: this.carrier,
            feedback: "",
            status: 0,
            vehicle: this.vehicle.title == undefined ? "" : this.vehicle.title,
            odometer: this.odometer,
            type: this.type,
            remarks: this.remarks,
          })
          .then((response) => {
            this.data = response.data;
            if (response.data.status == true) {
              this.uploadMultiOptionData(response.data.data);
            } else {
              this.uploadMultiOptionData(response.data.data);
            }

            this.showToast(
              "success",
              "Success",
              "All details has been stored successfully!"
            );

            this.$router.push({
              name: "driver-dashboard",
            });
          });
      } else {
        this.$http
          .post(`${serverUri}/inspections/vehicle`, {
            random_key: this.randomKey,
            date: this.date,
            driver_id: this.userId,
            company_id: this.companyId,
            carrier: this.carrier,
            feedback: this.message,
            status: this.check_status == 1 ? 3 : 2,
            vehicle: this.vehicle.title == undefined ? "" : this.vehicle.title,
            odometer: this.odometer,
            type: this.type,
            remarks: this.remarks,
          })
          .then((response) => {
            this.data = response.data;
            if (response.data.status == true) 
            {
              this.uploadMultiOptionData(response.data.data);
            } else {
              this.uploadMultiOptionData(response.data.data);
            }

            this.showToast(
              "success",
              "Success",
              "All details has been stored successfully!"
            );

            this.$router.push({
              name: "driver-dashboard",
            });
          });
      }
    },

    uploadMultiOptionData(mainId) {
      this.uploadDefectDataStatus = [];
      this.defectData.map((item, index) => {
        const formData = new FormData();
        formData.append("random_key", mainId); // appending
        formData.append("company_id", this.companyId); // appending
        formData.append("driver_id", this.userId); // appending
        formData.append("date", this.date); // appending
        formData.append("carrier", this.carrier); // appending
        formData.append("vehicle_id", this.vehicle.title); // appending
        formData.append("remarks", this.remarks); // appending
        formData.append("odometer", this.odometer); // appending
        formData.append("vehicle_defect_id", item.id); // appending
        formData.append("defect_category", item.catId); // appending
        formData.append("document_name", item.document); // appending
        formData.append("message", item.message); // appending

        axios
          .post(`${serverUri}/inspection/submit_form`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.status) {
              this.showToast(
                "success",
                "Success",
                "The Document File has been uploaded"
              );
              this.uploadDefectDataStatus.push(mainId);
            } else {
              this.showToast("danger", "Warning", res.data.message);
            }
          });
      });

      if (this.defectData.length > 0) {
        this.submitTask();
      }
    },
    setLoading: function (value) {
      // this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      this.showCheck = false;
      this.disableNext = false;
      console.log("Tab: " + tabIndex + " valid: " + isValid);
      this.currentTab = tabIndex;

    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },

    validateAsync: function (value) {

      if(value == 'step14')
      {
        console.log(value);
        console.log('-=--=0-=')
        this.nextEnable = false;
        this.nextTitle  = 'Finish';
      } else {
        this.nextTitle  = 'Next';
      }

      return new Promise((resolve, reject) => {
        if (this.date == "") {
          reject("Please choose any date");
        }

        if (this.vehicle == "") {
          reject("Please choose any vehicle");
        }

        if (this.odometer == "") {
          reject("Please choose any odometer");
        }

        if (this.type == "") {
          reject("Please choose type");
        }

        //   if(this.currentTab != this.selectedTab)
        //   {
        //     reject("Please choose a one Option");
        //   }

        // console.log(this.currentTab);
        // console.log(this.selectedTab);

        resolve(true);
        //  setTimeout(() => {
        //    if(this.count < 1){
        //     this.count ++
        //    	reject('This is a custom validation error message. Click next again to get rid of the validation')
        //    }else{
        //     this.count = 0
        //     resolve(true)
        //    }
        //  }, 1000)
      });
    },

    onSave() {
      if (this.document == "" && this.message == "") {
        this.showToast(
          "danger",
          "Warning",
          "Choose Vehicle defects document/video or enter any message"
        );
        return false;
      }

      this.defectData.push({
        id: this.selected,
        catId: this.selectedCatId,
        message: this.message,
        document: this.document,
      });

      this.$refs["modal-tall"].hide();
    },
    getFleetUsers() {
      this.$http
        .get(`${serverUri}/users/allfleetUser/${this.companyId}`)
        .then((response) => {
          this.data = response.data;
          response.data.map((item) => {
            this.assignee.push({
              fullName: item.name,
              key: item.id,
            });
          });
        });
    },

    submitTask() {
      var d = new Date();
      const DueDate = moment(d.setDate(d.getDate() + 1)).format("YYYY-MM-DD");
      const Tags = [{ title: "Inspections", label: "Inspections" }];
      const Assignee = this.assignee;

      if (this.defectData.length > 0) {
        const vehicleDetails = [];

        this.vehicleOptions.map((item, index) => {
          if (this.vehicle.title == item.title) {
            vehicleDetails.push(item.label);
          }
        });

        const firstDefect = [];
        this.vehicle_defects.map((item, index) => {
          if (this.defectData[0].id == item.id && item.status == 1) {
            firstDefect.push(item.title);
          }
        });

        const Title =
          vehicleDetails[0] + " Vehicle Inspection - " + firstDefect[0];

        axios
          .post(`${serverUri}/todo/add/${getUserData().id}`, {
            title: Title,
            status: "pending",
            dueDate: DueDate,
            assignee: Assignee,
            tags: Tags,
            description: this.verify_message +'<br>'+this.remarks,
            company: getUserData().company_id,
          })
          .then((res) => {
            if (res.data[0]) {
              return true;
            } else {
              return false;
            }
          });
      }
    },

    CategoryWiseData(catId) {
      const AllData = [];
      this.vehicle_defects.map((item, index) => {
        if (catId == item.category_id && item.status == 1) {
          AllData.push(item);
        }
      });
      return AllData;
    },
    getVehicleDefects() {
      axios
        .get(`${serverUri}/inspections/vehicle_defects/${this.companyId}`)
        .then((res) => {
          res.data.map((item, index) => {
            this.vehicle_defects.push(item);
          });
        });
    },
    getDriverSign() {
      this.$http
        .get(`${serverUri}/drivers/details/${this.userData.driver_id}`)
        .then((response) => {
          this.data = response.data;

          if (this.data[0].signature != null) {
            this.driverSign = this.data[0].signature;
          }
        });
    },

    ChangeVehicleDefects(event, Id, catId) {
      this.selected = "";
      this.message = "";
      this.document = "";
      this.selectedCatId = "";
      if (event.target.checked) {
        this.disableNext = true;
        this.selected = Id;
        this.selectedCatId = catId;
        if (this.vehicle_defects_data.indexOf(Id) === -1) {
          this.vehicle_defects_data.push(Id);
          // this.defectCategory.push()
        }

        this.$refs["modal-tall"].show();
        // this.nextEnable = false;

      } else {
        this.selected = "";
        this.selectedCatId = "";
        this.vehicle_defects_data.splice(Id, 1);
        console.log(this.defectData);

        let i = this.defectData.map((item) => item.id).indexOf(Id); // find index of your object
        this.defectData.splice(i, 1);
        console.log(this.defectData);
        // this.nextEnable = true;
      }
      console.log(this.vehicle_defects_data);
    },
    getCategories() {
      axios
        .get(
          `${serverUri}/inspections/vehicle_defects_category/${this.companyId}`
        )
        .then((res) => {
          res.data.map((item, index) => {
            if (item.status == 1) {
              this.category_data.push(item);
            }
          });
        });
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },

    onUpdate() {
      const SelectedVehicleData = [];
      this.vehicle_defects_data.forEach((key, value) => {
        if (key == true) {
          SelectedVehicleData.push(value);
        }
      });

      if (SelectedVehicleData.length > 0) {
        this.submitTask();
      }

      this.$http
        .post(`${serverUri}/inspections/vehicle`, {
          date: this.date,
          driver_id: this.userId,
          company_id: this.companyId,
          carrier: this.carrier,
          vehicle: this.vehicle.title == undefined ? "" : this.vehicle.title,
          odometer: this.odometer,
          vehicle_defects_data:
            SelectedVehicleData.length == 0
              ? ""
              : SelectedVehicleData.join(","),
          remarks: this.remarks,
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == true) {
            this.showToast("success", "Success", response.data.message);

            this.$router.push({
              name: "driver-dashboard",
            });
          } else {
            this.showToast("warning", "Failure", response.data.message);
          }
        });
    },
    validationForm() {
      //   this.$refs.simpleRules.validate().then((success) => {
      //     if (success) {
      //       // eslint-disable-next-line
      //       alert("password reset!");
      //     }
      //   });
    },
  },
  created() {
    const d = new Date();
    this.date = moment(d.setDate(d.getDate())).format("YYYY-MM-DD");
    axios
      .post(`${serverUri}/driver/all-drivers-vehicles`,
      {
        company_id : this.companyId,
        driver_id : this.userId
      })
      .then((res) => {
        res.data.map((item) => {
          this.vehicleOptions.push({
            title: item.id,
            label: item.tractor_id,
          });
          this.option.push({
            title: item.id,
            label: item.tractor_id + " (" + item.model + "-" + item.year + ")",
          });
          this.randomKey = Math.random() * 100;
        });
      });
  },
};
</script>
      
      <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
label {
  font-size: 18px;
}
.wizard-progress-with-circle {
  display: none;
}
.wizard-nav.wizard-nav-pills {
  display: none;
}
.custom-file-label {
  /* width: 219px; */
  font-size: 15px;
}
/* .close {
  display: none;
} */
.wizard-tab-container {
  background: white;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
#Details0 label {
  text-align: left;
}
.good-button {
  /* margin-right: 10px !important; */
  width: 125px;
}
.bad-button {
  /* margin-left: 10px !important; */
  width: 125px;
}
.custom-control-label {
  float: left;
}
.vue-form-wizard .wizard-tab-content {
  padding: 0px;
}
.vue-form-wizard .wizard-card-footer {
  padding: 0px;
}
button.wizard-btn {
  background: #7367f0 !important;
  border-color: #7367f0 !important;
}
#modal-inspection-check___BV_modal_content_ .close {
  display:none;
}
.defect-div {
  max-width:200px;
}
</style>
      